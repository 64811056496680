import { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { IMG_DOCUMENT_DARK, IMG_DOCUMENT_LIGHT } from '../../../assets/images'
import { getModule, getRelease, resetReleaseNote } from "../../../redux/slice/releaseNoteSlice"
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Flex,
    Grid,
    Icon,
    Image,
    Fade,
    useColorMode,
} from '@chakra-ui/react'
import { BsInfoCircle } from 'react-icons/bs'

function Sidebar({ handleGetContent, contentData, setContentData }) {
    const params = useParams()
    const [paramsModuleId, setParamsModuleId] = useState(params.module ? 'onload' : null)
    const [paramsReleaseId, setParamsReleaseId] = useState(params.release_note ? 'onload' : null)
    const { loadingModule, module, release } = useSelector(state => state.releaseNote)
    const { colorMode } = useColorMode()
    const dispatch = useDispatch()
    const location = useLocation()

    useEffect(() => {
        dispatch(getModule())

        return () => {
            dispatch(resetReleaseNote())
        }
    }, [dispatch])

    useEffect(() => {
        if (params.module && params.release_note) {
            if (module) {
                const getModuleId = module.find(m => m.key === params.module)?.id
                if (getModuleId && paramsModuleId === 'onload') {
                    setParamsModuleId(getModuleId)
                    dispatch(getRelease({ id_module: getModuleId }))
                    return
                } else if (!getModuleId && paramsModuleId === 'onload') {
                    console.log('tes');
                    setParamsModuleId(null)
                    return setContentData('notfound')
                } else if (getModuleId) {
                    setParamsModuleId(getModuleId)
                }

                if (release.length > 0) {
                    const releaseByModule = release.find(r => r.id === getModuleId)
                    if (releaseByModule && !releaseByModule.shouldLoad && paramsReleaseId === 'onload') {
                        const getReleaseId = releaseByModule.list.find(r => r.key === params.release_note)?.id
                        if (getReleaseId) {
                            setParamsReleaseId(getReleaseId)
                            handleGetContent(getModuleId, getReleaseId)
                            return
                        }
                        return setContentData('notfound')
                    }
                }
            }
        } else {
            setParamsModuleId(null)
            setParamsReleaseId(null)
            if (contentData !== 'shouldLoad' && contentData !== 'onload' && contentData !== null) setContentData(null)
        }
    }, [
        dispatch,
        module,
        release,
        params,
        paramsModuleId,
        paramsReleaseId,
        handleGetContent,
        contentData,
        setContentData
    ])

    useEffect(() => {
        setParamsReleaseId('onload')
    }, [location])

    if (loadingModule || paramsModuleId === 'onload') return (
        <Flex width={`260px`} direction='column' mt='.5rem'>
            <Grid
                borderBottomWidth='1px'
                w='full'
                h='49px'
                alignItems='center'
                templateColumns='auto 1fr'
                gap='.75rem'
            >
                <Skeleton style={{ width: '30px' }} />
                <Skeleton style={{ width: '150px' }} />
            </Grid>
            <Grid
                borderBottomWidth='1px'
                w='full'
                h='49px'
                alignItems='center'
                templateColumns='auto 1fr'
                gap='.75rem'
            >
                <Skeleton style={{ width: '30px' }} />
                <Skeleton style={{ width: '180px' }} />
            </Grid>
            <Grid
                borderBottomWidth='1px'
                w='full'
                h='49px'
                alignItems='center'
                templateColumns='auto 1fr'
                gap='.75rem'
            >
                <Skeleton style={{ width: '30px' }} />
                <Skeleton style={{ width: '140px' }} />
            </Grid>
            <Grid
                borderBottomWidth='1px'
                w='full'
                h='49px'
                alignItems='center'
                templateColumns='auto 1fr'
                gap='.75rem'
            >
                <Skeleton style={{ width: '30px' }} />
                <Skeleton style={{ width: '150px' }} />
            </Grid>
        </Flex>
    )

    if (module) {
        return (
            <Accordion
                allowToggle
                width={`260px`}
                defaultIndex={[module.map(m => m.id).indexOf(paramsModuleId)]}
            >
                {module.map((m, i) => {
                    const releaseByModule = release.find(r => r.id === m.id)

                    return (
                        <Fade in={m ? true : false} key={i}>
                            <AccordionItem padding={`14px 0 14px 7px`} borderTop='0' key={i} open={true}>
                                <AccordionButton
                                    padding={`0px`}
                                    _hover={{ bgColor: "transperant" }}
                                    onClick={() => releaseByModule.shouldLoad && dispatch(getRelease({ id_module: m.id }))}
                                >
                                    <Image
                                        height={`18px`}
                                        width={`18px`}
                                        mr={`13.25px`}
                                        src={m.logo}
                                        alt=''
                                    />
                                    <Box
                                        flex='1'
                                        textAlign='left'
                                        fontSize={`fs-sm`}
                                        fontWeight={`semibold`}
                                    >
                                        {m.name}
                                    </Box>
                                    <AccordionIcon ml='5px' mr='7px' />
                                </AccordionButton>
                                <AccordionPanel p='18px 0 4px 0'>
                                    {
                                        releaseByModule.shouldLoad ?
                                            <Flex direction={`column`}>
                                                <Skeleton style={{ width: '75%' }} />
                                                <Skeleton style={{ width: '90%' }} />
                                                <Skeleton style={{ width: '60%' }} />
                                            </Flex>
                                            :
                                            (
                                                releaseByModule.list.length === 0 ?
                                                    <Fade in={true}>
                                                        <Flex
                                                            fontSize={`fs-sm`}
                                                            fontWeight={`semibold`}
                                                            padding={`11px 0`}
                                                            align={`center`}
                                                            justify='center'
                                                            gap={`10px`}
                                                        >
                                                            <Icon as={BsInfoCircle} ml='-18px' fontWeight='semibold' />
                                                            Empty release note
                                                        </Flex>
                                                    </Fade>
                                                    :
                                                    releaseByModule.list.map((r, i) => {
                                                        const toUrl = `/release-notes/${m.key}/${r.key}`

                                                        return (
                                                            <Fade in={r ? true : false} key={i}>
                                                                <Box
                                                                    as={NavLink}
                                                                    display={`flex`}
                                                                    flex='1'
                                                                    textAlign='left'
                                                                    fontSize={`fs-sm`}
                                                                    fontWeight={`semibold`}
                                                                    padding={`11px 0`}
                                                                    pl='14px'
                                                                    flexDirection={`row`}
                                                                    alignItems={`center`}
                                                                    gap={`9px`}
                                                                    to={toUrl}
                                                                    borderLeft='3px solid'
                                                                    borderColor='chakra-body-bg'
                                                                    _activeLink={colorMode === 'light' ? {
                                                                        borderLeft: '3px solid',
                                                                        borderColor: 'border-active-color',
                                                                        boxShadow: '0px 1px 4px rgba(172, 172, 172, 0.25)'
                                                                    } : {
                                                                        borderLeft: '3px solid',
                                                                        borderColor: 'border-active-color',
                                                                        boxShadow: '0px 1px 4px rgba(67, 67, 67, 0.25)',
                                                                        backgroundColor: 'navbar-bg'
                                                                    }}
                                                                    onClick={() => toUrl !== location.pathname && handleGetContent(m.id, r.id)}
                                                                >
                                                                    <Image
                                                                        src={IMG_DOCUMENT_LIGHT}
                                                                        alt=''
                                                                        display={colorMode === 'dark' && 'none'}
                                                                    />
                                                                    <Image
                                                                        src={IMG_DOCUMENT_DARK}
                                                                        alt=''
                                                                        display={colorMode === 'light' && 'none'}
                                                                    />
                                                                    {r.title}
                                                                </Box>
                                                            </Fade>
                                                        )
                                                    })
                                            )
                                    }
                                </AccordionPanel>
                            </AccordionItem>
                        </Fade>
                    )
                })}
            </Accordion>
        )
    }
}

export default Sidebar