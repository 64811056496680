import Sidebar from "./component/Sidebar"
import Navbar from "../../components/Navbar"
import Wrapper from "../../components/Wrapper"
import { GET_CONTENT } from "../../api/requests/ReleaseNotes"
import Content from "./component/Content"
import { useParams } from "react-router-dom"
import {
    Box,
    Container,
    Divider,
    Fade,
    Flex,
    Grid,
    Spinner
} from "@chakra-ui/react"
import { useState } from "react"
import Skeleton from "react-loading-skeleton"
import Blank from "./component/Blank"
import NotFound from "./component/NotFound"
import Head from "../../components/Head"

function ReleaseNote() {
    const params = useParams()
    const [contentData, setContentData] = useState(params.module && params.release_note ? 'shouldLoad' : null)

    const handleGetContent = async (id_module, id_release_note) => {
        setContentData('onload')
        const content = await GET_CONTENT({ id_module, id_release_note })
        if (content.error) return setContentData(null)
        setContentData(content)
    }

    return (
        <Wrapper>
            <Head title='Release Notes - Officeless Docs' />
            <Navbar />
            <Container maxW='container.xl'>
                <Grid templateColumns='auto auto 1fr'>
                    <Sidebar handleGetContent={handleGetContent} contentData={contentData} setContentData={setContentData} />
                    <Divider orientation='vertical' h='full' minH='calc(100vh - calc(var(--chakra-sizes-nav-height) * 1.25))' />
                    {
                        contentData === 'shouldLoad' ?
                            <Fade in={true}>
                                <Flex
                                    w='full'
                                    h='400px'
                                    align='center'
                                    justify='center'
                                    gap='1rem'
                                >
                                    <Spinner size='xl' color='border-active-color' />
                                </Flex>
                            </Fade>
                            :
                            (
                                contentData === 'onload' ?
                                    <Fade in={true}>
                                        <Box pl='1rem'>
                                            <Flex
                                                p='1rem'
                                                direction='column'
                                            >
                                                <Flex gap='.75rem'>
                                                    <Skeleton width='150px' />
                                                </Flex>
                                                <Skeleton width="350px" height="30px" />
                                                <Flex gap='1.5rem'>
                                                    <Skeleton width="150px" />
                                                    <Skeleton width="100px" />
                                                </Flex>
                                            </Flex>
                                            <Divider />
                                            <Box p='1rem'>
                                                <Skeleton width='75%' />
                                                <Skeleton width='80%' />
                                                <Skeleton width='65%' />
                                            </Box>
                                        </Box>
                                    </Fade>
                                    :
                                    (
                                        contentData === null ?
                                            <Blank />
                                            :
                                            (
                                                contentData === 'notfound' ?
                                                    <NotFound />
                                                    :
                                                    <Content data={contentData} />
                                            )
                                    )
                            )
                    }
                </Grid>
            </Container>
        </Wrapper>
    )
}

export default ReleaseNote