import { Box } from '@chakra-ui/react'

function Wrapper({ children }) {
    return (
        <Box pt='calc(var(--chakra-sizes-nav-height) * 1.25)' w='wrapper-width'>
            {children}
        </Box>
    )
}

export default Wrapper
