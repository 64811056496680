import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GET_MODULE, GET_LIST_BY_MODULE } from '../../api/requests/ReleaseNotes';

const initialState = {
    loadingModule: false,
    module: null,
    release: []
}

export const getModule = createAsyncThunk('releaseNote/getModule', async (params, thunkAPI) => {
    return await GET_MODULE()
})

export const getRelease = createAsyncThunk('releaseNote/getRelease', async (data, thunkAPI) => {
    const list = await GET_LIST_BY_MODULE(data)
    return {
        id: data.id_module,
        list: list 
    }
})

const releaseNoteSlice = createSlice({
    name: 'releaseNote',
    initialState,
    reducers: {
        resetReleaseNote: state => {
            return state = {
                loadingModule: false,
                module: null,
                release: []
            }
        }
    },
    extraReducers: builder => {
        builder.addCase(getModule.pending, (state, action) => {
            state.loadingModule = true
        })
        builder.addCase(getModule.fulfilled, (state, action) => {
            if (state.module === null) {
                state.module = action.payload
                state.loadingModule = false
                state.release = action.payload.map(m => ({
                    id: m.id,
                    shouldLoad: true,
                    list: []
                }))
            }
        })

        builder.addCase(getRelease.fulfilled, (state, action) => {
            const release = state.release.filter(r => r.id !== action.payload.id)
            release.push({
                id: action.payload.id,
                shouldLoad: false,
                list: action.payload.list
            })
            state.release = release
        })
    }
});

export const { resetReleaseNote } = releaseNoteSlice.actions

export default releaseNoteSlice.reducer