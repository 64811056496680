import { IMG_LOGO, IMG_LOGO_DARK } from '../assets/images'
import { NavLink } from 'react-router-dom'
import { ROUTE_URL } from '../router/url'
import { BsMoonFill, BsSunFill } from 'react-icons/bs'
import { Box, Container, Flex, Icon, Image, Link, useColorMode, useColorModeValue } from '@chakra-ui/react'

function Navbar() {
    const { colorMode, toggleColorMode } = useColorMode()
    const navMenu = [
        {
            url: ROUTE_URL.DOCUMENTATION,
            label: 'Documentation'
        },
        {
            url: ROUTE_URL.RELEASE_NOTE,
            label: 'Release Notes'
        },
    ]

    const themeSwitch = {
        width: 100,
        height: 31,
        padding: 4
    }

    return (
        <Box
            h='nav-height'
            bgColor='navbar-bg'
            boxShadow={`0px 4px 19px ${useColorModeValue('rgba(136, 136, 136, 0.25)', 'rgba(0, 0, 0, 0.25)')}`}
            position='fixed'
            top='0'
            left='0'
            w='100vw'
            pr='var(--chakra-sizes-scrollbar-width)'
            zIndex={`999`}
        >
            <Container maxW='container.xl' h='full'>
                <Flex justify='space-between' align='center' w='full' h='full'>
                    <Box>
                        <Image src={IMG_LOGO} w='216px' h='28px' display={colorMode === 'dark' && 'none'} />
                        <Image src={IMG_LOGO_DARK} w='216px' h='28px' display={colorMode === 'light' && 'none'} />
                    </Box>
                    <Flex h='full' gap='2rem'>
                        <Flex h='full'>
                            {
                                navMenu.map((nav, i) => (
                                    <Link
                                        key={i}
                                        as={NavLink}
                                        to={nav.url}
                                        h='full'
                                        display='flex'
                                        alignItems='center'
                                        textDecor='none'
                                        fontSize='fs-sm'
                                        fontWeight='medium'
                                        padding='0 17px'
                                        borderBottom='2px solid'
                                        borderColor='navbar-bg'
                                        _hover={{ textDecor: 'none' }}
                                        _activeLink={{
                                            color: 'font-color-second',
                                            borderBottom: '2px solid',
                                            borderColor: 'border-active-color'
                                        }}
                                    >{nav.label}</Link>
                                ))
                            }
                        </Flex>
                        <Flex h='full' align='center'>
                            <Box
                                w={`${themeSwitch.width}px`}
                                h={`${themeSwitch.height}px`}
                                p={`${themeSwitch.padding}px`}
                                borderRadius='9px'
                                position='relative'
                                display='flex'
                                justifyContent='space-between'
                                bgColor={useColorModeValue('#E7E7E7', '#606060')}
                            >
                                <Box
                                    w={`${(themeSwitch.width / 2) - (themeSwitch.padding * 2)}px`}
                                    h={`${themeSwitch.height - (themeSwitch.padding * 2)}px`}
                                    borderRadius='6px'
                                    position='absolute'
                                    transition='left .2s !important'
                                    top={`${themeSwitch.padding}px`}
                                    bgColor={useColorModeValue('#FFFFFF', '#444444')}
                                    left={colorMode === 'light' ? `${themeSwitch.padding}px` : `${(themeSwitch.width / 2) + themeSwitch.padding}px`}
                                />
                                <Flex
                                    w={`${(themeSwitch.width / 2) - (themeSwitch.padding * 2)}px`}
                                    h={`${themeSwitch.height - (themeSwitch.padding * 2)}px`}
                                    justify='center'
                                    align='center'
                                    cursor='pointer'
                                    position='relative'
                                    color={useColorModeValue('#FFD748', 'fs')}
                                    onClick={() => colorMode !== 'light' && toggleColorMode()}
                                >
                                    <Icon as={BsSunFill} fontSize='15px' />
                                </Flex>
                                <Flex
                                    w={`${(themeSwitch.width / 2) - (themeSwitch.padding * 2)}px`}
                                    h={`${themeSwitch.height - (themeSwitch.padding * 2)}px`}
                                    justify='center'
                                    align='center'
                                    cursor='pointer'
                                    position='relative'
                                    color={useColorModeValue('fs', '#FFD748')}
                                    onClick={() => colorMode !== 'dark' && toggleColorMode()}
                                >
                                    <Icon as={BsMoonFill} fontSize='13px' />
                                </Flex>
                            </Box>
                        </Flex>
                    </Flex>
                </Flex>
            </Container>
        </Box>
    )
}

export default Navbar