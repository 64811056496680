import React from 'react'
import { Helmet } from 'react-helmet-async'

function Head({ title, description }) {
    return (
        <Helmet>
            {title && <title>{title}</title>}
            {description && <meta name="description" content={description} />}
            {title && <meta name="og:title" content={title} />}
            {description && <meta name="og:description" content={description} />}
        </Helmet>
    )
}

export default Head