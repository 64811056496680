import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from './redux/store';
import theme from './theme';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { HelmetProvider } from 'react-helmet-async';
import Head from './components/Head';

console.log(theme)

const defaultTitle = document.title
const defaultDescription = document.querySelector('meta[name="description"]').getAttribute('content')
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <ChakraProvider theme={theme}>
                    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
                    <HelmetProvider>
                        <Head title={defaultTitle} description={defaultDescription} />
                        <App />
                    </HelmetProvider>
                </ChakraProvider>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);
