import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
    config: {
        initialColorMode: 'system',
        useSystemColorMode: true
    },
    breakpoints: {
        xs: '414px',
        sm: '576px',
        md: '768px',
        lg: '992px',
        xl: '1200px',
        xxl: '1400px'
    },
    semanticTokens: {
        colors: {
            'chakra-body-bg': {
                _light: '#FFFFFF',
                _dark: '#252525'
            },
            'chakra-body-bg-second': {
                _light: '#FBFBFB',
                _dark: '#2E2E2E'
            },
            'chakra-body-text': {
                _light: '#606060',
                _dark: '#FFFFFF'
            },
            'scrollbar-bg': {
                _light: '#F2F2F2',
                _dark: 'RGBA(255, 255, 255, 0.06)'
            },
            'scrollbar-thumb': {
                _light: '#AFAFAF',
                _dark: 'RGBA(255, 255, 255, 0.24)'
            },
            'navbar-bg': {
                _light: '#FFFFFF',
                _dark: '#444444'
            },
            'font-color-second': {
                _light: '#071C4D',
                _dark: '#FFFFFF'
            },
            'code-block-bg': {
                _light: '#F8F7Fa',
                _dark: '#1E2128'
            },
            'border-active-color': {
                _light: '#1A85E4',
                _dark: '#1A85E4'
            },
        }
    },
    fontSizes: {
        'fs-lg': '30px',
        'fs-md': '18px',
        'fs': '14px',
        'fs-sm': '12px'
    },
    sizes: {
        'scrollbar-width': '.5rem',
        'wrapper-width': 'calc(100vw - var(--chakra-sizes-scrollbar-width))',
        'nav-height': '52px',
    }
})

export default theme