import { Routes, Route } from 'react-router-dom'
import { ROUTE_URL } from './url'
import Home from '../pages/Home'
import ReleaseNote from '../pages/ReleaseNote/ReleaseNote'
import Documentation from '../pages/Documentation'
import ErrorPage from '../pages/ErrorPage'

const Router = () => {
    return (
        <Routes>
            <Route path={ROUTE_URL.HOME} element={<Home />} />
            <Route path={ROUTE_URL.RELEASE_NOTE} element={<ReleaseNote />} />
            <Route path={ROUTE_URL.RELEASE_NOTE_DETAIL} element={<ReleaseNote />} />
            <Route path={ROUTE_URL.DOCUMENTATION} element={<Documentation />} />
            <Route path='/*' element={<ErrorPage code='404' message='Page not found' />} />
        </Routes>
    )
}

export default Router