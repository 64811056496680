import {
    Divider,
    Flex,
    Text
} from '@chakra-ui/react'
import React from 'react'

function ErrorPage({ code, message }) {
    return (
        <Flex
            w='100vw'
            h='100vh'
            justify='center'
            align='center'
            gap='1.5rem'
        >
            <Text fontSize='26px' fontWeight='light' letterSpacing='2px'>{code}</Text>
            <Divider orientation='vertical' maxH='40px' borderColor='var(--chakra-colors-chakra-body-text)' />
            <Text fontSize='24px' fontWeight='light' letterSpacing='2px'>{message}</Text>
        </Flex>
    )
}

export default ErrorPage