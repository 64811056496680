import xhr from "../core";
import API_URL from "../url";

export const GET_MODULE = async () => {
    try {
        const res = await xhr().get(API_URL.GET_MODULE)
        if (!res.error) return res.data
        throw Error(res.message)
    } catch (error) {
        console.error(error);
        return []
    }
}

export const GET_LIST_BY_MODULE = async (data) => {
    try {
        const res = await xhr().post(API_URL.RELEASE_NOTES.GET_LIST_BY_MODULE, data)
        if (!res.error) return res.data
        throw Error(res.message)
    } catch (error) {
        console.error(error);
        return []
    }
}

export const GET_CONTENT = async (data) => {
    try {
        const res = await xhr().post(API_URL.RELEASE_NOTES.GET_CONTENT, data)
        if (!res.error) return res.data
        throw Error(res.message)
    } catch (error) {
        console.error(error);
        alert(error)
        return {
            error: true
        }
    }
}