import { Fade, Flex, Icon, Text } from "@chakra-ui/react"
import { BsInfoCircle } from "react-icons/bs"

function Blank() {
    return (
        <Fade in={true}>
            <Flex
                w='full'
                h='400px'
                align='center'
                justify='center'
                gap='1rem'
            >
                <Icon as={BsInfoCircle} />
                <Text fontWeight='medium'>No content selected to display</Text>
            </Flex>
        </Fade>
    )
}

export default Blank