import Navbar from '../components/Navbar'
import ErrorPage from './ErrorPage'

function Documentation() {
    return (
        <>
            <Navbar />
            <ErrorPage code='503' message='Under Construction' />
        </>
    )
}

export default Documentation