import axios from "axios"

const xhr = () => {
    const config = {
        baseURL: process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_NOCODE_URL + process.env.REACT_APP_COMPANY_ID,
        headers: {
            "Apikey": process.env.REACT_APP_API_KEY,
        }
    }

    const axiosInstance = axios.create(config)
    axiosInstance.interceptors.response.use(res => res.data)

    return axiosInstance
}

export default xhr