import {
    Box,
    Divider,
    Fade,
    Flex,
    Text
} from "@chakra-ui/react"
import EditorJS from '@editorjs/editorjs';
import EDITOR_JS_TOOLS from "./tools.editor";
import { useEffect, useState } from "react";
import Head from "../../../components/Head";

function Content({ data }) {
    const [isEditorJs, setIsEditorJs] = useState(false)

    const getContentDate = timestamp => {
        let dateFormat = new Date(timestamp);
        dateFormat = dateFormat.toString()
        const date = dateFormat.substring(8, 10)
        const month = dateFormat.substring(4, 7)
        const year = dateFormat.substring(11, 15)
        const time = dateFormat.substring(16, 24)
        return `${date} ${month} ${year}, ${time}`
    }

    useEffect(() => {
        if (isEditorJs === 'shouldRender' && typeof data.release_notes.content === 'object') {
            const editor = new EditorJS({
                holder: "editor-js",
                tools: EDITOR_JS_TOOLS,
                data: data.release_notes.content,
                readOnly: true,
                logLevel: 'ERROR'
            });

            editor.isReady.then(() => {
                document.querySelectorAll('.link-tool__anchor').forEach(el => {
                    el.setAttribute('style', 'color: var(--chakra-colors-chakra-body-text) !important;')
                })
            })

            return setIsEditorJs(true)
        }

        if (!isEditorJs) return setIsEditorJs('shouldRender')
    }, [isEditorJs, data]);

    return (
        <>
            <Head title={data.release_notes.title} description={data.release_notes.content.blocks[0].data.text} />
            <Fade in={data ? true : false}>
                <Box pl='1rem'>
                    <Flex
                        p='1rem'
                        direction='column'
                    >
                        <Flex gap='.75rem'>
                            <Text fontSize='fs-sm'>{data.release_notes.product_module.name}</Text>
                            <Text fontSize='fs-sm'>/</Text>
                            <Text fontSize='fs-sm'>{data.release_notes.title}</Text>
                        </Flex>
                        <Text
                            fontSize='fs-lg'
                            fontWeight='semibold'
                            color='font-color-second'
                        >
                            {data.release_notes.title}
                        </Text>
                        <Flex gap='1.5rem'>
                            <Text fontSize='fs-sm'>{getContentDate(data.release_notes.created_at)}</Text>
                            <Text fontSize='fs-sm'>Surbafie</Text>
                        </Flex>
                    </Flex>
                    <Divider />
                    {
                        typeof data.release_notes.content === 'string' ?
                            <Box p='1rem' whiteSpace='pre-line'>{data.release_notes.content}</Box>
                            :
                            (
                                typeof data.release_notes.content === 'object' ?
                                    <Box p='1rem' id='editor-js'></Box>
                                    :
                                    <Box p='1rem' color='red.500'>Error : Unexpected type of content !</Box>
                            )
                    }

                </Box>
            </Fade>
        </>
    )
}

export default Content