import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_URL } from '../router/url'

function Home() {
    const navigate = useNavigate()

    useEffect(() => {
        navigate(ROUTE_URL.DOCUMENTATION)
    }, [navigate])

    return
}

export default Home